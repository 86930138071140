.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  text-color: #61dafb;
}

div.Creatura {
	display: inline-block;
	padding: 5px;
	color: #000;
	border: 1px solid black;
	font-size: 0.8em;
	border-radius: 15px; 
}

div.Foto {
	display: block;
	border-radius: 15px; 
	padding: 5px;
	color: red;
	border: 1px solid black;
	font-size: 0.8em;
	background-color: white;
	margin-top: 10px;
	margin-bottom: 10px;
	background-image: url("http://uabstyle.it/immagini/articoli/2/829.jpg");

}

div.Vita {
	display: inline-block;
	padding: 5px;
	color: red;
	border: 1px solid black;
	font-size: 0.8em;
}

div.Attacco {
	display: inline-block;
	padding: 5px;
	color: #000;
	border: 1px solid black;
	font-size: 0.8em;
	margin: -1px;
}

div.Difesa {
	display: inline-block;
	padding: 5px;
	color: blue;
	border: 1px solid black;
	font-size: 0.8em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

span.carta {
  display: inline-block;
  width: 330px;
  height:430px;
  padding: 5px;
  border: 1px solid blue; 
  margin: 5px;
  border-radius: 35px;  
  background-color: lightgreen;
}
span.nome {
  display: inline-block;
  width: 200px;
  height:260px;
  padding: 5px;
  border: 1px solid blue; 
  margin: 5px;
  border-radius: 15px;  
  background-color: lightgreen;
}